import React from 'react';
import Paper from '@mui/material/Paper';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import SectionContent from '../components/SectionComponent';

function Proyectos() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh',
        bgcolor: '#007AEC',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          bgcolor: 'white',
          overflowY: 'auto',
          width: '90%',
          marginX: "5%",
          mb: isMobile ? '50%' : '5%',
          padding: isMobile ? 0 : '1rem',
          marginTop: isMobile ? '10vh' : '15vh'
        }}
      >
        <SectionContent
          title="Sistema de evaluación de desempeño en RCP durante prácticas de primeros auxilios en ambientes de simulación con Amazon Alexa"
          content="El proyecto titulado Sistema de Evaluación de Desempeño en RCP Durante Prácticas de Primeros Auxilios en Ambientes de Simulación con Amazon Alexa, desarrollado por Oliver Daniel Atondo Gastelum, se enfoca en la creación de un asistente virtual para mejorar las prácticas de soporte vital básico (BLS) mediante la simulación de escenarios de reanimación cardiopulmonar (RCP). Utiliza tecnologías como Bluetooth, IoT y un servidor web para ofrecer retroalimentación interactiva y precisa. El sistema permite a los usuarios interactuar a través de voz, sin necesidad de usar las manos, mejorando así el entrenamiento en RCP. El proyecto busca integrar nuevas funcionalidades y mejorar la interactividad y personalización en las respuestas durante las simulaciones de RCP."
          image={"https://raw.githubusercontent.com/OliverAtondo/Portfolio2.0/main/portfolio/src/assets/Alexa.png?token=GHSAT0AAAAAACLNHFJF7CBJ4SYQMCYKELYSZMLMEGQ"}
          isMobile={isMobile}
          linkButton= "Ir a la página"
          pageLink= "https://biomedica.atondo.net"
        />
      </Paper>
    </Box>
  );
}


export default Proyectos;
