import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import Inicio from './pages/Inicio';
import ProjectsPage from './pages/Proyectos';
import BraguarsPage from './pages/Braguars';
import ContactPage from './pages/Contacto';

function App() {
  return (
    <Router>
      <ResponsiveAppBar classname="appbar"/>
      <Routes>
        <Route path="/INICIO" element={<Inicio />} />
        <Route path="/" element={<Inicio />} />
        <Route path="/PROYECTOS" element={<ProjectsPage />} />
        <Route path="/BRAGUARS" element={<BraguarsPage />} />
        <Route path="/CONTACTO" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}

export default App;
