import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';

function SectionContent({ title, content, image, isMobile, linkButton, pageLink }) {

  const handleButtonClick = () => {
    window.location.href = pageLink; 
  };

    return (
      <Grid container spacing={2} sx={{ marginBottom: isMobile ? '0rem' : '0rem' }}>
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: isMobile ? '80vw' : '25vw',
              margin: '5%',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ padding: isMobile ? '1rem' : 0 }}>
            <Typography variant="h5" sx={{ marginBottom: '1rem' }}>{title}</Typography>
            <Typography variant="body1" sx={{ textAlign: 'justify', mr: isMobile ? 0 : "5%" }}>{content}</Typography>
            {linkButton &&
                <Box sx={{ marginTop: '1rem' }}> 
                <Button variant="contained" color="primary" onClick={handleButtonClick}>
                    {linkButton}
                </Button>
                </Box>
            }
        </Grid>
      </Grid>
    );
  }
  

export default SectionContent;
