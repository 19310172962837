import React from 'react';
import Paper from '@mui/material/Paper';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import SectionContent from '../components/SectionComponent';

function Inicio() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh',
        bgcolor: '#007AEC',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          bgcolor: 'white',
          overflowY: 'auto',
          width: '90%',
          marginX: "5%",
          mb: isMobile ? '50%' : '5%',
          padding: isMobile ? 0 : '1rem',
          marginTop: isMobile ? '10vh' : '15vh'
        }}
      >
        <SectionContent
          title="Acerca de Mí"
          content="Soy Oliver Daniel Atondo Gastelum, un apasionado Desarrollador de Software con 2 años de experiencia en el mundo de la programación web y móvil. Mi trayectoria académica comenzó en el Instituto Tecnológico de Tijuana, donde me gradué en Ingeniería Biomédica, especializándome en Tecnologías Biomédicas Aplicadas. Esta especialidad me ha permitido combinar mi interés en la tecnología con el campo de la biomedicina.

          Mi entusiasmo por la tecnología no se detiene ahí; me fascina el Machine Learning y siempre estoy buscando nuevas formas de integrarlo en mis proyectos. Además, disfruto del desarrollo web y móvil, especialmente para la plataforma iOS, y me gusta explorar las posibilidades que ofrece la tecnología en la nube.
          
          Fuera del mundo de la programación y el desarrollo, tengo un gran amor por la música. Me encanta descubrir nuevos géneros y artistas. También disfruto de explorar la ciudad en busca de nuevos restaurantes y cafés, lo cual me permite desconectar y encontrar inspiración en los pequeños placeres de la vida. Estos hobbies son una parte esencial de quién soy, ya que me permiten equilibrar mi vida profesional con mis pasiones personales."
          image={"https://raw.githubusercontent.com/OliverAtondo/Portfolio2.0/main/portfolio/src/assets/PerfilFinal.png?token=GHSAT0AAAAAACLNHFJFBE54MC3RUNZLEJ7AZMJDALA"}
          isMobile={isMobile}
        />
        <SectionContent
          title="Mis Proyectos"
          content="Estudiante promedio."
          image={null}
          isMobile={isMobile}
        />
      </Paper>
    </Box>
  );
}


export default Inicio;
